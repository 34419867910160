.resume {
  font-size: 13px;
}

.resume :global(.section) {
  margin: 0.5rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  overflow-x: auto;
  overflow-y: hidden;
}

.resume :global(.image) {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid lightgray;
  text-align: center;
}

.resume :global(.tables) :global(.section) {
  margin: 0;
  width: 40%;
}

.resume :global(.tables) :global(.section) table {
  width: 100%;
}

.resume :global(.tables) :global(.section):first-child {
  margin-left: 1rem;
}

.resume :global(.tables) :global(.section):last-child {
  margin-right: 1rem;
}

.resume h5 {
  margin-top: 1rem;
}

.resume h2 {
  font-size: 1.7rem;
  border-bottom: 3px solid #034472;
  /* border-bottom: ; */
  text-align: left !important;
  font-weight: bold;
}

.resume :global(.tables) {
  display: flex;
  gap: 0.2rem;
}

.resume table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-right: 1rem;
  border: 1px solid rgb(185, 185, 185);
}

.resume td {
  padding: 0.2em;
  text-align: center;
  border-right: solid 1px rgb(171, 172, 207);
  border-left: solid 1px rgb(201, 255, 252);
}

.resume tr:nth-child(even) {
  background-color: rgb(236, 236, 236);
  border: none;
}

.resume td {
  min-width: 1.3rem;
}

.resume :global(.entreverdes) {
  overflow-y: scroll;
}

.resume :global(.entreverdes) tr {
  background-color: transparent;
}

.resume :global(.label) {
  font-weight: bold;
}

.resume :global(.headerLabel) {
  font-weight: bold !important;
  background-color: #034472 !important;
  color: white;
}

.resume thead {
  background-color: #034472;
}

.resume th {
  text-align: center;
  padding: 0.35rem;
  color: white;
}

.resume Button {
  border-radius: 1px;
}

.resume input {
  font-size: 10px;
  width: 85%;
  margin: 0;
  margin-left: 5%;
  margin-right: 10%;
  background-color: rgba(255, 0, 0, 0);
  border: none;
  border-bottom: 1px solid black;
  height: 1.5rem;
}

.resume textarea:focus,
.resume input:focus {
  outline: none;
  background-color: rgb(201, 202, 255);
}

.resume input:hover {
  background-color: rgb(201, 202, 255);
}
