.bar {
  width: 100vw !important;
  background: #034472;
  color: white;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding: 0.65rem;
  position: fixed;
  z-index: 10;
}

.logo {
  width: 10%;
  /* margin-left: 2rem; */

  margin-bottom: 0.07rem;
  /* transform: translate(0, -0.2rem); */
}

.logo img {
  max-width: 6.5rem;
  height: auto;
  margin-left: 2rem;
}

.header_title {
  width: 80%;
  font-size: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  margin-left: 0rem;
}
