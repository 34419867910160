.resume {
  font-size: 14px;
}

.resume :global(.section) {
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  overflow-x: auto;
  overflow-y: hidden;
}

.resume :global(.image) {
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  text-align: center;
}

.resume :global(.tables) :global(.section) {
  margin: 0;
  width: 33%;
}

.resume :global(.tables) :global(.section) table {
  width: 100%;
}

.resume :global(.tables) :global(.section):first-child {
  margin-left: 1rem;
}

.resume :global(.tables) :global(.section):last-child {
  margin-right: 1rem;
}

.resume h5 {
  margin-top: 1.5rem;
}

.resume h2 {
  font-size: 1.5rem;
  border-bottom: 3px solid #034472;
  /* border-bottom: ; */
  text-align: left !important;
}

.resume :global(.tables) {
  display: flex;
  gap: 1rem;
}

.resume table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-right: 1rem;
  border: 1px solid rgb(206, 206, 206);
}

.resume td {
  padding: 0.75rem;
  text-align: center;
  border-right: solid 1px rgb(201, 202, 255);
  border-left: solid 1px rgb(201, 255, 252);
}

.resume tr:nth-child(even) {
  background-color: rgb(236, 236, 236);
  border: none;
}

.resume :global(.entreverdes) {
  overflow-y: scroll;
}

.resume :global(.entreverdes) tr {
  background-color: transparent;
}

.resume :global(.label) {
  font-weight: bold;
}

.resume :global(.headerLabel) {
  font-weight: bold !important;
  background-color: #034472 !important;
  color: white;
}

.resume thead {
  background-color: #034472;
}

.resume th {
  text-align: center;
  padding: 0.75rem;
  color: white;
}

.resume Button {
  border-radius: 1px;
}

/* .resume input {
  font-size: 16px;
} */
