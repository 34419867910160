.form th {
  vertical-align: middle;
}

.form label {
  margin-bottom: 0 !important;
  font-weight: bold;
}

.form td {
  vertical-align: middle;
}

h6 {
  padding-top: 0.3rem;
  color: rgb(105, 105, 105);
}

.plantilla {
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: rgb(78, 177, 12);
  text-decoration: none;
  width: 10rem;
  text-align: center;
  color: white;
}

.plantilla:hover {
  text-decoration: none;
  color: white;
  background-color: rgb(107, 175, 62);
}
.text {
  text-decoration: none;
  color: white;
}

.text:hover {
  text-decoration: none;
  color: white;
}

TextField {
  background: none;
}
