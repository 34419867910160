.menu {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  background-color: #3155a5;
  flex-direction: column;
  padding: 1rem 2rem;
  padding-right: 6rem;
  margin-right: -32rem;
  transition: margin 0.4s;
}

.menu button {
  border: none;
  font-size: inherit !important;
  background-color: transparent;
  padding: 0;
  color: white;
}

.back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(49, 49, 49, 0.527);
}

.back:hover {
  cursor: pointer;
}

.show {
  margin-right: 0;
}

.link {
  margin-bottom: 1rem !important;
  transition: all 0.2s;
}

.link span {
  color: white;
  text-decoration: none;
}

.link:hover {
  text-decoration: none !important;
  transform: scale(1.1);
}

.icon {
  transition: all 0.2s;
}

.icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}
